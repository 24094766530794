var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect } from "react";
import Helmet from "react-helmet";
import { getTemplateComponentByKey } from "cms/utils/templateUtils";
import { formatPageData } from "cms/utils/templatePropsUtils";
import SiteContext from "cms/components/SiteContext";
import { createUrlWithoutQuery } from "cms/utils/urlUtils";
import { getLogoPath } from "cms/utils/commonUtils";
import JsonLd from "cms/components/JsonLd";
import getEnvironmentPromise from "cms/back-office/services/getEnvironment";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Templates } from "types/templates.enum";
var Page = function (props) {
    var _a = props.page, page = _a === void 0 ? {} : _a, _b = props.templatesSettings, templatesSettings = _b === void 0 ? {} : _b;
    var site = useContext(SiteContext);
    var siteSettings = site.settings, host = site.host, siteName = site.name;
    var socialNetworks = (siteSettings || {}).socialNetworks;
    var _c = siteSettings || {}, _d = _c.logoHeader, logoHeader = _d === void 0 ? null : _d, logoFooter = _c.logoFooter;
    var logo = logoHeader || logoFooter;
    var logoPath = getLogoPath(logo);
    var id = page.id, title = page.title, description = page.description, image = page.image, template = page.template, fullPath = page.fullPath;
    var trackPageView = useMatomo().trackPageView;
    var _e = React.useState(""), phoneNumber = _e[0], setPhoneNumber = _e[1];
    React.useEffect(function () {
        getEnvironmentPromise().then(function (_a) {
            var arpavie_phone_number = _a.arpavie_phone_number;
            if (arpavie_phone_number) {
                setPhoneNumber(arpavie_phone_number);
            }
        });
    }, []);
    useEffect(function () {
        if (title) {
            trackPageView({ documentTitle: title });
        }
    }, [id, title, trackPageView]);
    if (socialNetworks && !Array.isArray(socialNetworks)) {
        socialNetworks = [socialNetworks];
    }
    if (!page || !page.id) {
        return null;
    }
    var TemplateTag = getTemplateComponentByKey(template);
    var isEstablishmentPage = template === Templates.ESTABLISHMENT;
    var structuredData = "";
    if (!isEstablishmentPage) {
        structuredData = "{\n    \"@context\": \"https://schema.org\",\n    \"@type\": \"Organization\",\n    \"name\": \"".concat(siteName, "\",\n    \"telephone\": \"").concat(phoneNumber, "\",\n    \"url\": \"").concat(host).concat(fullPath, "\",\n    \"logo\":\"").concat(logoPath, "\",\n    \"description\": \"").concat(typeof description === "string" ? description.replace(/"/g, "&quot;") : description, "\",\n    \"sameAs\": [\n      ").concat(socialNetworks &&
            socialNetworks.reduce(function (str, socialNetwork, index) {
                return "".concat(str, "\"").concat(socialNetwork.url, "\"").concat(socialNetworks.length !== index + 1 ? "," : "", " ");
            }, ""), "\n    ]\n  }");
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Helmet, { children: [_jsx("title", { children: title }), _jsx("meta", { name: "Description", content: description || "" }), _jsx("meta", { property: "og:url", content: "".concat(host).concat(fullPath) }), _jsx("meta", { property: "og:title", content: title || "" }), _jsx("meta", { property: "og:description", content: description || "" }), image && _jsx("meta", { property: "og:image", content: image.url || "" }), _jsx("meta", { property: "og:type", content: "website" }), _jsx("link", { rel: "canonical", href: createUrlWithoutQuery("".concat(host).concat(fullPath)) })] }), !isEstablishmentPage && _jsx(JsonLd, { structuredData: structuredData }), _jsx(TemplateTag, __assign({ page: formatPageData(page) }, templatesSettings))] }));
};
export default Page;
